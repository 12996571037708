import create from "zustand";
import { immer } from "zustand/middleware/immer";

let store: any = (set: any) => ({
  guest: {},
  setGuest: (guest: any) => {
    set((state: any) => {
      state.guest = guest;
    });
  },
});

store = immer(store);

const useAuth = create(store);

export { useAuth };
