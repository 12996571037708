import swal from "sweetalert";
import { useForm } from "react-hook-form";

import {
  useFeedbackListQuery,
  useSubmitFeedbackMutation,
} from "../../generated/graphql";
import { useAuth } from "../../store";

interface FeedbackForm {
  feedback_type_id: number;
  feedback: string;
}

const useFeedback = () => {
  const [createFeedback, { loading: loadingSubmitFeedback }] =
    useSubmitFeedbackMutation();
  const guest = useAuth((state: any) => state.guest);
  const {
    register: registerFeedback,
    handleSubmit: handleSubmitFeedback,
    reset: resetFeedback,
    formState: { errors: errorsFeedback, isDirty },
  } = useForm<FeedbackForm>();

  const { data: feedbackList, loading: loadingFeedbackList } =
    useFeedbackListQuery({
      variables: {
        propertyID: guest?.property_id,
      },
    });

  const onSubmitFeedback = async (data: FeedbackForm) => {
    const res = await createFeedback({
      variables: {
        input: {
          property_id: guest?.property_id,
          room_id: guest?.room_id,
          feedback: data?.feedback,
          feedback_type_id: data?.feedback_type_id,
        },
      },
    });
    if (res.data?.createFeedback?.code == "200") {
      swal({
        text: "Feedback Submitted Successfully",
        icon: "success",
      });
    } else {
      swal({
        text: res.data?.createFeedback?.message,
        icon: "error",
      });
    }
    resetFeedback();
  };

  return {
    registerFeedback,
    handleSubmitFeedback,
    loadingFeedbackList,
    onSubmitFeedback,
    feedbackType: feedbackList?.getFeedbackTypeList,
    loadingSubmitFeedback,
  };
};

export default useFeedback;
