import { useAuth } from "../../store";
import { useHotelDetailsQuery } from "../../generated/graphql";

const useDining = () => {
  const guest = useAuth((state: any) => state.guest);

  const { data: dining, loading: loadingDining } = useHotelDetailsQuery({
    variables: {
      propertyID: guest?.property_id,
    },
  });
  return {
    dining: dining?.getMeta?.dining,
    loadingDining,
    guest,
  };
};

export default useDining;
