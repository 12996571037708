import ShareIcon from "@mui/icons-material/Share";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";

import useHotelInfo from "./hotel-info-hooks";
import { HeaderLayout, Layout } from "../InRoomDining";
import { Box, CardActions, CardContent, Chip, Typography } from "@mui/material";
import { FullScreenLoader, ServiceCard } from "../../components";

const HotelInfo = () => {
  const { guest, hotelDetails, loadingHotelDetails } = useHotelInfo();

  const { dir_con, about, hotel_rooms, hotel_services } = hotelDetails || {};
  const hotelRoomsImage = hotel_rooms && hotel_rooms[0]?.img;
  const hotelServicesImage = hotel_services && hotel_services[0]?.img;

  if (loadingHotelDetails) {
    return <FullScreenLoader />;
  }
  return (
    <Layout>
      <HeaderLayout title="Hotel Information" />

      <Box
        sx={{
          p: 2,
          pt: 0,
          flex: 1,
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          // TODO:
          // This color is only used once keeping it here for a while
          backgroundColor: "#F4F4F4",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <ServiceCard
          showInfo={true}
          image={about?.img as string}
          description={about?.desc as string}
        >
          <CardContent sx={{ px: 2, pb: 0 }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                mb: 0.5,
                fontWeight: 600,
                fontSize: (theme) => theme.spacing(2.5),
              }}
            >
              Hotel Jaipur
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textDecorationLine: "underline" }}
            >
              {dir_con?.address}
            </Typography>
          </CardContent>
          <CardActions sx={{ px: 2, py: 2 }}>
            <Chip
              variant="filled"
              color="primary"
              label="+91 98555 77458"
              icon={<WifiCalling3Icon fontSize="small" color="primary" />}
            />
            <Chip
              variant="filled"
              color="primary"
              label="Share Directions"
              icon={<ShareIcon fontSize="small" />}
            />
          </CardActions>
        </ServiceCard>

        <ServiceCard
          showInfo={false}
          label={"Hotel Rooms"}
          image={hotelRoomsImage as string}
          path={`/${guest?.room_id}/hotel-rooms`}
        />

        <ServiceCard
          showInfo={true}
          image={dir_con?.img as string}
          description={dir_con?.address}
          label={"Direction And Contacts"}
        />

        <ServiceCard
          showInfo={false}
          label={"Hotel Services"}
          image={hotelServicesImage as string}
          path={`/${guest?.room_id}/hotel-services`}
        />
      </Box>
    </Layout>
  );
};
export default HotelInfo;
