import { Box, Typography } from '@mui/material'
import React from 'react'

const ErrorPage = () => {
  return (
    <>
      <Box
        style={{
          width: '17%',
          height: '17%',
          backgroundColor: '#fff',
          marginLeft: '230px',
          marginTop: '100px',
        }}
        component="img"
        alt="Orders"
        src="https://m.media-amazon.com/images/I/418Jmnejj8L._AC_SX466_.jpg"
      />
      <Typography
        sx={{ fontSize: 20, textAlign: 'center', color: '#fff', paddingTop: 2 }}
      >
        You Are Not Authorized User
      </Typography>
    </>
  )
}

export default ErrorPage
