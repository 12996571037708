import { useAuth } from "../../store";
import { useHotelDetailsQuery } from "../../generated/graphql";

const useHotelRooms = () => {
  const guest = useAuth((state: any) => state.guest);

  const { data: hotelRooms, loading: loadingHotelRooms } = useHotelDetailsQuery(
    {
      variables: {
        propertyID: guest?.property_id,
      },
    }
  );
  return {
    hotelRooms: hotelRooms?.getMeta?.hotel_rooms,
    loadingHotelRooms,
  };
};

export default useHotelRooms;
