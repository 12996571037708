import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputBase,
  Rating,
  Typography,
  useTheme,
} from "@mui/material";
import swal from "sweetalert";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import {
  DropDown,
  FeedbackIcon,
  FullScreenLoader,
  PromotionsIcon,
} from "../../../components";
import {
  useFeedbackListQuery,
  useSubmitFeedbackMutation,
} from "../../../generated/graphql";
import { CardPaper } from "./CardPaper";
import { useAuth } from "../../../store";

const FeedbackAndPromotions = () => {
  const theme = useTheme();
  const guest = useAuth((state: any) => state.guest);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [feedBackType, setFeedBackType] = React.useState<any>();
  const [ratings, setRatings] = React.useState<number | null>(0);
  const { data: feedbackList, loading: loadingFeedbackList } =
    useFeedbackListQuery({
      variables: {
        propertyID: guest?.property_id,
      },
    });
  const [createFeedback, { loading: loadingSubmitFeedback }] =
    useSubmitFeedbackMutation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRatings(0);
    setComment("");
    setFeedBackType("");
  };

  React.useEffect(() => {}, []);

  const onSubmitFeedback = async () => {
    const res = await createFeedback({
      variables: {
        input: {
          property_id: guest?.property_id,
          room_id: guest?.room_id,
          feedback_type_id: feedBackType?.id,
          feedback: comment,
        },
      },
    });
    if (res.data?.createFeedback?.code == "200") {
      swal({
        text: "Feedback Submitted Successfully",
        icon: "success",
      });
    } else {
      swal({
        text: res.data?.createFeedback?.message,
        icon: "error",
      });
    }
    handleClose();
  };

  React.useEffect(() => {
    if (!feedBackType && feedbackList) {
      setFeedBackType(
        feedbackList?.getFeedbackTypeList &&
          feedbackList?.getFeedbackTypeList[0]
      );
    }
  }, [feedBackType, feedbackList?.getFeedbackTypeList]);

  if (loadingFeedbackList || loadingSubmitFeedback) return <FullScreenLoader />;

  return (
    <Box sx={{ mt: 3, display: "flex" }}>
      <CardPaper
        sx={{ p: 1.5, flex: 1 }}
        onClick={() => navigate("promotions")}
      >
        <PromotionsIcon sx={{ height: 60, width: 60 }} />
        <Typography variant="h6" sx={{ color: "primary.main" }}>
          Promotions
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Praesent id magna grav ida, accumsan diam id sollicitudin ante
        </Typography>
      </CardPaper>

      <CardPaper sx={{ p: 1.5, flex: 1, ml: 1.5 }} onClick={handleClickOpen}>
        <FeedbackIcon sx={{ height: 60, width: 60 }} />
        <Typography variant="h6" sx={{ color: "primary.main" }}>
          Leave Feedback
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Aenean cursus ornare de onec rutrum urna et aug eleifendmaximu sunc.
        </Typography>
      </CardPaper>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FeedbackIcon
            sx={{ height: 60, width: 60 }}
            customColor={theme.palette.primary.main}
          />
          <Typography variant="h6" sx={{ my: 1 }}>
            Leave Feedback
          </Typography>

          <DropDown
            extractId={(option) => option.id}
            onClick={setFeedBackType}
            extractLabel={(option) => option?.name}
            options={feedbackList?.getFeedbackTypeList || []}
            selectedOption={feedBackType}
          />

          <Rating
            size="large"
            value={ratings}
            onChange={(event, newValue) => {
              setRatings(newValue);
            }}
            icon={
              <StarIcon fontSize="inherit" sx={{ color: "primary.main" }} />
            }
            emptyIcon={
              <StarBorderIcon
                fontSize="inherit"
                sx={{ color: "primary.main" }}
              />
            }
            sx={{ mb: 2 }}
          />
          <InputBase
            rows={3}
            multiline
            value={comment}
            placeholder="Write your comment here"
            onChange={(e) => setComment(e.target.value)}
            sx={{
              p: 1.5,
              width: 280,
              borderRadius: 1.25,
              border: "1px solid #C5C5C5",
            }}
          />
        </DialogContent>
        <DialogActions sx={{ px: 2, justifyContent: "stretch" }}>
          <Button onClick={handleClose} sx={{ flex: 1 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSubmitFeedback}
            autoFocus
            sx={{ flex: 1 }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export { FeedbackAndPromotions };
