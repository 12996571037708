import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import Mycart from "../Mycart";
import MenuDrawer from "../MyOrders";

import useCart from "./cart-hook";

interface CartItem {
  id: number;
  name: string;
  price: number;
  quantity: number;
  img: string;
}
const AddToCart = () => {
  const {
    cart,
    handleDecrementCart,
    handleIncrementCart,
    calculateTotal,
    calculateAmount,
    calculateServiceFee,
    handleOrder,
  } = useCart();

  return (
    <>
      {cart.length > 0 ? (
        <>
          <Box mb={2}>
            <Typography>Approximate waiting time – 15 min</Typography>
            {cart?.map((cartItem: CartItem) => {
              return (
                <>
                  <Card
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="div" variant="h5">
                          {cartItem.name}
                        </Typography>
                        <ButtonGroup
                          size="small"
                          aria-label="small outlined button group"
                        >
                          <Button
                            onClick={() => handleDecrementCart(cartItem.id)}
                          >
                            -
                          </Button>
                          <Button disabled>{cartItem.quantity}</Button>
                          <Button
                            onClick={() => handleIncrementCart(cartItem.id)}
                          >
                            +
                          </Button>
                        </ButtonGroup>
                      </CardContent>
                    </Box>
                    <Box padding={2}>
                      <img
                        src={cartItem.img}
                        alt={cartItem.name}
                        width={100}
                      ></img>
                    </Box>
                  </Card>
                  <Divider />
                </>
              );
            })}
            <Card>
              <Box style={{ padding: "15px" }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography align="left" color="#fff">
                    Order Amount
                  </Typography>
                  <Typography align="right">
                    {"$" + calculateAmount(cart)}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography align="left" color="#fff">
                    Service Fee*
                  </Typography>
                  <Typography align="right">
                    {"$" + calculateServiceFee(cart)}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h5" color="#fff">
                    Total
                  </Typography>
                  <Typography align="right">
                    ${calculateTotal(cart).toFixed(2)}
                  </Typography>
                </Stack>
              </Box>
            </Card>
          </Box>
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleOrder(calculateTotal(cart).toFixed(2))}
              sx={{ width: "100%" }}
            >
              Confirm Order
            </Button>
          </Box>
        </>
      ) : (
        <Mycart />
      )}
    </>
  );
};

export default AddToCart;
