import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button as MiUiButton,
  styled,
  Typography,
  SxProps,
  Theme,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import * as React from "react";
import { camelCase, startCase } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useServices } from "../../store";
import { FullScreenLoader } from "../../components";
import useHotelServices from "./hotel-services-hooks";
import { SERVICES_ICONS } from "../../constant/services";
import { CustomButton, Layout, Paper } from "../InRoomDining";
import { SubmissionSuccessIcon } from "../../components/Icons";

const Button = styled(MiUiButton)(({ theme }) => ({
  borderRadius: theme.spacing(1.25),
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
  "&:hover": {
    backgroundColor: "#ffffff",
    boxShadow:
      "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
  },
}));

interface HeaderProps {
  serviceList: any[];
  sx?: SxProps<Theme>;
  currentService: string;
  onClick: (service: string) => void;
}

const Header = (props: HeaderProps) => {
  const { sx, onClick, currentService, serviceList } = props;
  const icon = (SERVICES_ICONS as any)[currentService];

  // Hooks
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ...(sx ? sx : {}) }}>
      <Button
        sx={{
          py: 1.5,
          px: 2.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        fullWidth
        disableRipple
        disableFocusRipple
        size="large"
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {icon}
          <Typography
            variant="body2"
            sx={{ ml: 2, fontWeight: 500, color: "primary.main" }}
          >
            {currentService}
          </Typography>
        </Box>
        {openMenu ? (
          <KeyboardArrowUpIcon sx={{ color: "text.primary" }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ color: "text.primary" }} />
        )}
      </Button>
      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleClose}>
        {serviceList.map((service) => (
          <MenuItem
            key={service}
            onClick={() => {
              onClick(service);
              handleClose();
            }}
          >
            {startCase(camelCase(service))}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

interface ServiceItemProps {
  service: any;
  isLast: boolean;
  addToService: any;
  removeFromService: any;
}

const ServiceItem = (props: ServiceItemProps) => {
  const { isLast, service, addToService, removeFromService } = props;

  const canAddMultiple = Boolean(service.qty_by_guest);
  const services = useServices((state: any) => state.services);
  const itemInCart = React.useMemo(() => {
    return services.find((f: any) => f.id === service.id)?.count || 0;
  }, [services]);
  const isItemInCart = Boolean(itemInCart);

  return (
    <Paper
      key={service.id}
      sx={{
        mt: 2,
        py: 2,
        px: 2.5,
        display: "flex",
        mb: isLast ? 2 : 0,
        justifyContent: "space-between",
      }}
    >
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {service.task}
      </Typography>

      {!isItemInCart && (
        <CustomButton
          size="small"
          variant="outlined"
          sx={{ alignSelf: "center" }}
          onClick={() => addToService(service.id)}
        >
          Add
        </CustomButton>
      )}

      {isItemInCart && !canAddMultiple && (
        <CustomButton
          size="small"
          variant="contained"
          sx={{ alignSelf: "center" }}
          onClick={() => removeFromService(service.id)}
        >
          Added
        </CustomButton>
      )}

      {isItemInCart && canAddMultiple && (
        <ButtonGroup
          size="small"
          variant="contained"
          sx={{
            border: "none",
            color: "#ffffff",
            alignSelf: "center",
            backgroundColor: "primary.main",
          }}
        >
          <IconButton
            size="small"
            onClick={() => addToService(service.id)}
            sx={{ color: "#ffffff" }}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
          <CustomButton
            size="small"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{ border: "none !important" }}
          >
            {itemInCart}
          </CustomButton>

          <IconButton
            size="small"
            onClick={() => removeFromService(service.id)}
            sx={{ color: "#ffffff" }}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        </ButtonGroup>
      )}
    </Paper>
  );
};

function HotelService() {
  const {
    loading,
    submitServices,
    servicesList,
    currentServiceList,
    itemsInCart,
    cancelServices,
    addToService,
    removeFromService,
    currentServiceSimplified,
    setCurrentService,
    navigate,
    open,
    handleClickOpen,
    handleClose,
    modalMessage,
  } = useHotelServices();

  if (loading) return <FullScreenLoader />;

  return (
    <Layout>
      <Header
        sx={{ p: 2 }}
        serviceList={servicesList}
        currentService={currentServiceSimplified}
        onClick={(service: string) => setCurrentService(service)}
      />
      <Box
        sx={{
          px: 2,
          flex: 1,
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {currentServiceList?.map((service: any, index: number, arr: any) => {
          return (
            <ServiceItem
              key={service.id}
              service={service}
              addToService={addToService}
              isLast={index === arr.length - 1}
              removeFromService={removeFromService}
            />
          );
        })}
      </Box>
      <Box sx={{ p: 2, display: "flex" }}>
        <MiUiButton
          size="large"
          sx={{ flex: 1 }}
          variant="outlined"
          onClick={() => {
            cancelServices();
            navigate(-1);
          }}
        >
          Cancel
        </MiUiButton>
        <MiUiButton
          size="large"
          variant="contained"
          sx={{ flex: 2, ml: 1.25 }}
          disabled={itemsInCart <= 0}
          onClick={submitServices}
        >
          Request {itemsInCart} Items
        </MiUiButton>
      </Box>

      <Dialog open={open} onClose={() => {}}>
        <DialogContent
          sx={{
            pb: 0,
            width: "300px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <SubmissionSuccessIcon sx={{ height: 60, width: 60 }} />
          <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
            {modalMessage}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 2, justifyContent: "stretch" }}>
          <Button
            variant="text"
            sx={{
              flex: 1,
              boxShadow: "none",
              "&:hover": { boxShadow: "none" },
            }}
            onClick={() => {
              handleClose();
              navigate(-1);
              cancelServices();
            }}
          >
            Go back
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default HotelService;
