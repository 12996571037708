import {
  Box,
  Menu,
  Button,
  styled,
  MenuItem,
  Typography,
  IconButton,
  Paper as MiUiPaper,
  SxProps,
  Theme,
  CircularProgress,
  Backdrop,
  ButtonGroup,
} from "@mui/material";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useRoomDining from "./room-dining-hook";
import { VegNonIcon } from "../../components/Icons";
import { startCase, camelCase } from "lodash";
import { useCart } from "../../store";
import { FullScreenLoader } from "../../components";

const ALL_MENU = "ALL_MENU";

interface HeaderLayoutProps {
  title: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

export const HeaderLayout = ({ sx, title, children }: HeaderLayoutProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        height: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow:
          "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
        ...(sx ? sx : {}),
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton disableRipple onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ color: "text.primary" }} />
        </IconButton>
        <Typography variant="h6" sx={{ ml: 1 }}>
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
interface HeaderProps {
  categories: string[];
  currentCategory: string;
  onClick: (category: string) => void;
}

const Header = (props: HeaderProps) => {
  // Hooks
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { categories, currentCategory, onClick } = props;

  return (
    <HeaderLayout title="In Room Dining">
      <Button
        onClick={handleClick}
        sx={{ color: "text.primary" }}
        endIcon={openMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {startCase(camelCase(currentCategory))}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {categories.map((category) => (
          <MenuItem
            key={category}
            onClick={() => {
              onClick && onClick(category);
              handleClose();
            }}
          >
            {startCase(camelCase(category))}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </HeaderLayout>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const { roomID } = useParams();
  const cart = useCart((state: any) => state.cart);
  const totalItems = cart.reduce(
    (prev: number, current: any) => prev + current.count,
    0
  );

  if (totalItems === 0) return null;

  return (
    <Box sx={{ p: 1 }}>
      <Button
        fullWidth
        variant="contained"
        onClick={() => navigate(`/${roomID}/cart`)}
      >
        View Cart ({totalItems} Items)
      </Button>
    </Box>
  );
};

export const Paper = styled(MiUiPaper)(({ theme }) => ({
  border: "1px solid #F0F0F0",
  boxShadow: "none",
  borderRadius: theme.spacing(1.875),
  // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  paddingTop: "0px !important",
  paddingBottom: "0px !important",
  paddingRight: "4px !important",
  paddingLeft: "4px !important",
  borderRadius: "5px",
  fontSize: "13px",
}));

interface LayoutProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
}

export const Layout = ({ children, sx }: LayoutProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        ...(sx ? sx : {}),
      }}
    >
      {children}
    </Box>
  );
};

interface FoodItemCardProps {
  item: any;
}

export const FoodItemCard = ({ item }: FoodItemCardProps) => {
  const cart = useCart((state: any) => state.cart);
  const addToCart = useCart((state: any) => state.addToCart);
  const removeFromCart = useCart((state: any) => state.removeFromCart);
  const itemInCart = cart.find((f: any) => f.id === item.id)?.count || 0;

  const { name, price, img, id, isVegan } = item;
  return (
    <Paper
      sx={{
        mt: 2,
        padding: 1.5,
        display: "flex",
      }}
    >
      <img
        src={img}
        alt={name}
        width={100}
        height={100}
        style={{ borderRadius: "10px", objectFit: "cover" }}
      />
      <Box sx={{ ml: 1.5, flex: 1, p: 1.5 }}>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          AED {price}
        </Typography>
        {/* <VegNonIcon /> */}

        <img
          style={{ marginTop: "4px" }}
          alt={isVegan ? "veg" : "non-veg"}
          src={`/assets/${isVegan ? "veg" : "non-veg"}.png`}
        />
      </Box>
      {!Boolean(itemInCart) ? (
        <CustomButton
          size="small"
          variant="outlined"
          sx={{ alignSelf: "center" }}
          onClick={() => addToCart(id)}
        >
          Add
        </CustomButton>
      ) : (
        <ButtonGroup
          size="small"
          variant="contained"
          sx={{
            border: "none",
            color: "#ffffff",
            alignSelf: "center",
            backgroundColor: "primary.main",
          }}
        >
          <IconButton
            size="small"
            onClick={() => addToCart(id)}
            sx={{ color: "#ffffff" }}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
          <CustomButton
            size="small"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{ border: "none !important" }}
          >
            {itemInCart}
          </CustomButton>

          <IconButton
            size="small"
            onClick={() => removeFromCart(id)}
            sx={{ color: "#ffffff" }}
          >
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        </ButtonGroup>
      )}
    </Paper>
  );
};

// TODO: Extract All component above into a separate folder
const InRoomDining = () => {
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const [currentCategory, setCurrentCategory] = React.useState(
    category || ALL_MENU
  );
  const { loading, foodMenu, categories } = useRoomDining();
  const foodCategories = [ALL_MENU, ...categories];
  const filteredMenu = React.useMemo(() => {
    if (currentCategory === ALL_MENU) return foodMenu;
    return foodMenu.filter((item) => item.name === currentCategory);
  }, [currentCategory, foodMenu]);
  // const cart = useCart((state: any) => state.cart);
  const addToCart = useCart((state: any) => state.addToCart);

  if (loading) return <FullScreenLoader />;

  return (
    <Layout>
      <Header
        categories={foodCategories}
        onClick={setCurrentCategory}
        currentCategory={currentCategory}
      />
      <Box
        sx={{
          p: 2,
          flex: 1,
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {/* Header */}
        {filteredMenu.map((menu, foodMenuIndex) => {
          return (
            <React.Fragment key={menu.name}>
              <Box
                sx={{
                  mt: foodMenuIndex === 0 ? 1 : 3,
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2">
                  {startCase(camelCase(menu.name))}
                </Typography>
                <Typography variant="body2">
                  {menu.items.length} Items
                </Typography>
              </Box>
              <Box>
                {menu.items.map((item) => (
                  <FoodItemCard key={item?.id} item={item} />
                ))}
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
      <Footer />
    </Layout>
  );
};

export default InRoomDining;
