import React from "react";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";

import { Drawer } from "../Drawer";

interface CommonProps {
  image: string;
  label?: string;
  children?: React.ReactNode;
}

type ConditionalProps =
  | {
      path: string;
      showInfo: false;
      description?: never;
    }
  | {
      path?: never;
      showInfo: true;
      description?: string;
    };

type DashboardProps = CommonProps & ConditionalProps;

const ServiceCard = (props: DashboardProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { image, label, path, children, showInfo, description } = props;

  const handleOpen = () => {
    if (showInfo) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card
        sx={{
          mt: 2,
          borderRadius: 0,
          boxShadow: "none",
          ...(label ? { position: "relative" } : {}),
        }}
      >
        <CardMedia
          alt={label}
          image={image}
          component="img"
          onClick={showInfo ? () => handleOpen() : () => navigate(path)}
          sx={{ height: 250 }}
        />
        {label && (
          <Typography
            variant="h6"
            sx={{
              color: "#fff",
              position: "absolute",
              left: (theme) => theme.spacing(2),
              bottom: (theme) => theme.spacing(1),
              fontSize: (theme) => theme.spacing(2.5),
            }}
          >
            {label}
          </Typography>
        )}

        {/* Extra stuff to render */}
        {children}
      </Card>
      {showInfo && (
        <Drawer
          open={open}
          image={image}
          name={label as string}
          handleOpen={handleOpen}
          handleClose={handleClose}
          desc={description as string}
        />
      )}
    </>
  );
};

export { ServiceCard };
