import swal from "sweetalert";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../store";
import { CartContext } from "../../context/Context";
import { useCreateOrderMutation } from "../../generated/graphql";

const useCart = () => {
  const navigate = useNavigate();
  const { cart, addToCart, increaseCartItem, decreaseCartItem } =
    useContext(CartContext);

  const orderData = cart.map((cartData: any) => {
    return {
      dineInItem_id: cartData.id,
      quantity: cartData.quantity,
      extra_name: "xyz",
    };
  });

  const guest = useAuth((state: any) => state.guest);
  const [upsertOrder, { loading: loadingCreateOrder }] =
    useCreateOrderMutation();

  //Handle Remove Cart Item
  const handleDecrementCart = (id: number) => {
    decreaseCartItem?.(id);
  };
  // handle increment cart quantity handleUpdateCart
  const handleIncrementCart = (id: number) => {
    increaseCartItem?.(id);
  };

  //Calculate Total Cart Amount
  const calculateTotal = (items: any[]) =>
    items.reduce((acc, item) => acc + 3 + item.quantity * item.price, 0);

  //Calculate Total Cart Amount
  const calculateAmount = (items: any[]) =>
    items.reduce((acc, item) => acc + item.quantity * item.price, 0);
  //Calculate Service Fee
  const calculateServiceFee = (items: any[]) =>
    items.reduce((acc, item) => acc + 3, 0);

  //Calculate Total Cart Item
  const calculateCartItem = (items: any[]) =>
    items.reduce((acc, item) => acc + item.quantity, 0);

  const handleOrder = async (total: number) => {
    try {
      const res = await upsertOrder({
        variables: {
          orderInput: {
            cart_items: orderData,
            property_id: guest?.property_id,
            guest_id: guest?.guest_id,
            guest_name: guest?.guest_name,
            room_id: guest?.room_id,
            total: total,
            // status:Status.Pending
          },
        },
      });

      if (res.data?.upsertOrder?.id) {
        addToCart?.([]);
        swal({
          text: "Order Created Successfully",
          icon: "success",
        });
      } else {
        swal({
          text: "Order Not Created",
          icon: "error",
        });
      }
    } catch (err) {
      swal({
        text: `Error -${err}`,
        icon: "error",
      });
    }
  };

  return {
    cart,
    addToCart,
    handleDecrementCart,
    handleIncrementCart,
    calculateTotal,
    calculateAmount,
    calculateServiceFee,
    calculateCartItem,
    handleOrder,
  };
};

export default useCart;
