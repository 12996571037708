export * from "./hooks";

export const delay = (timeInMs: number) =>
  new Promise((resolve) => setTimeout(resolve, timeInMs));

export const getLocalStorage = (key: string) =>
  JSON.parse(window.localStorage.getItem(key) as string);

export const setLocalStorage = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value));
