import { useNavigate } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";

import { CardPaper } from "./CardPaper";
import { useAuth } from "../../../store";
import { useHotelDetailsQuery } from "../../../generated/graphql";

const InfoCard = () => {
  const guest = useAuth((state: any) => state.guest);
  const navigate = useNavigate();
  const { data, loading } = useHotelDetailsQuery({
    variables: {
      propertyID: guest?.property_id,
    },
  });

  const descriptionString = data?.getMeta?.about?.desc;

  return (
    <CardPaper sx={{ mt: 3, px: 3, py: 2.5 }}>
      <Box sx={{ mb: 1, textAlign: "center" }}>
        <img src="/assets/app-logo.png" alt="App Logo" height="100px" />
      </Box>

      {descriptionString && (
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {descriptionString.slice(0, 160)}
          <Link
            underline="none"
            onClick={() => navigate("hotel-information")}
            sx={{ ml: 0.5, fontSize: "inherit", fontWeight: 600 }}
          >
            Read More..
          </Link>
        </Typography>
      )}
    </CardPaper>
  );
};

export { InfoCard };
