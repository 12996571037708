import * as React from "react";
import { useNavigate } from "react-router-dom";
import { camelCase, groupBy, startCase, snakeCase } from "lodash";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Menu, MenuItem, SxProps, Theme, Typography } from "@mui/material";

import {
  Header,
  Slider,
  InfoCard,
  SliderCard,
  FeedbackAndPromotions,
} from "./components";
import { useAuth } from "../../store";
import { useMenuItem } from "../InRoomDining/room-dining-hook";
import { usePropertyServicesQuery } from "../../generated/graphql";
import { SERVICES_ICONS, IN_ROOM_DINING_ICONS } from "../../constant/services";

export const useServiceList = () => {
  const guest = useAuth((state: any) => state.guest);
  const { data, loading } = usePropertyServicesQuery({
    variables: {
      property_id: guest.property_id,
    },
  });

  const computedData = React.useMemo(() => {
    if (!data) {
      return { servicesList: [], groupedServices: [] };
    }
    const parsedTaskList = JSON.parse((data.getServices as any)?.tasklist);
    const servicesList = Object.keys(parsedTaskList).map(snakeCase);

    return { servicesList, groupedServices: parsedTaskList };
  }, [data]);

  return { ...computedData, loading };
};
interface DropDownProps {
  options: any[];
  sx?: SxProps<Theme>;
  selectedOption: any;
  onClick: (option: any) => void;
  extractId: (option: any) => string;
  extractLabel: (option: any) => string;
}

export const DropDown = (props: DropDownProps) => {
  const { sx, options, selectedOption, extractLabel, extractId, onClick } =
    props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ...(sx ? sx : {}) }}>
      <Button
        sx={{
          py: 1.5,
          px: 2.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        fullWidth
        disableRipple
        disableFocusRipple
        size="large"
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            sx={{ ml: 2, fontWeight: 500, color: "primary.main" }}
          >
            {extractLabel(selectedOption)}
          </Typography>
        </Box>
        {openMenu ? (
          <KeyboardArrowUpIcon sx={{ color: "text.primary" }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ color: "text.primary" }} />
        )}
      </Button>
      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem
            key={extractId(option)}
            onClick={() => {
              onClick(option);
              handleClose();
            }}
          >
            {extractLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { data: menuData, loading: menuDataLoading } = useMenuItem();
  const { servicesList, loading: serviceListLoading } = useServiceList();

  const inRoomDiningList = React.useMemo(() => {
    if (!menuData) return [];
    return Object.keys(groupBy(menuData.getEdibles, (data: any) => data.type));
  }, [menuData]);

  return (
    <Box
      sx={{
        p: 2,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Header />
      <InfoCard />

      <Slider title="Services" loading={serviceListLoading}>
        {servicesList?.map((service, index) => {
          const camelCaseName = startCase(camelCase(service));
          const icon = (SERVICES_ICONS as any)[camelCaseName];
          return (
            <SliderCard
              key={service}
              icon={icon}
              title={camelCaseName}
              index={index}
              link={`services?service=${service}`}
            />
          );
        })}
      </Slider>

      <Slider
        title="In Room Dining"
        loading={menuDataLoading}
        onTitleClick={() => navigate("in-room-dining")}
      >
        {inRoomDiningList?.map((category, index) => {
          const camelCaseName = startCase(camelCase(category));
          const icon = (IN_ROOM_DINING_ICONS as any)[camelCaseName];
          return (
            <SliderCard
              icon={icon}
              index={index}
              key={category}
              title={camelCaseName}
              link={`in-room-dining?category=${category}`}
            />
          );
        })}
      </Slider>

      <FeedbackAndPromotions />
    </Box>
  );
};

export { Dashboard };
