import * as React from "react";
import SendIcon from "@mui/icons-material/Send";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { Box, IconButton, InputBase, SxProps, Theme } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import {
  CustomButton,
  HeaderLayout,
  Layout,
} from "../InRoomDining";
import { useChat } from "../../store";
import { FullScreenLoader } from "../../components";

enum MessageType {
  Sent,
  Received,
}

const useWebSocket = (onMessageReceived: any) => {
  const [isReady, setIsReady] = React.useState(false);
  const ws = React.useRef<any>(null);

  React.useEffect(() => {
    const socket = new WebSocket("wss://ws.postman-echo.com/raw");

    socket.onopen = () => setIsReady(true);
    socket.onclose = () => setIsReady(false);
    socket.onmessage = (event) => onMessageReceived(event.data);

    ws.current = socket;

    return () => {
      socket.close();
    };
  }, []);

  // bind is needed to make sure `send` references correct `this`
  return [isReady, ws.current?.send.bind(ws.current)];
};

const Footer = ({ send }: any) => {
  const [chatInput, setChatInput] = React.useState("");

  const sendMessage = () => {
    if (chatInput.trim().length === 0) return;

    send(chatInput);
    setChatInput("");
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "15px",
          backgroundColor: "background.grey",
        }}
      >
        <InputBase
          // rows={2}
          // multiline
          value={chatInput}
          sx={{ m: 1.25, ml: 1, flex: 1 }}
          placeholder="Select One of the option"
          onChange={(e) => setChatInput(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              sendMessage();
            }
          }}
        />
        <IconButton color="primary" sx={{ p: 1 }}>
          <AttachFileIcon />
        </IconButton>
        <IconButton color="primary" sx={{ p: 1 }} onClick={sendMessage}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

interface MessageProps {
  text: string;
  sx?: SxProps<Theme>;
}

const Message = ({ sx, text }: MessageProps) => {
  return (
    <Box
      sx={{
        p: 1.5,
        width: "70%",
        ...(sx ? sx : {}),
      }}
    >
      {text}
    </Box>
  );
};

function Chat() {
  // const [messages, setMessages] = React.useState<any>([]);
  const chat = useChat((state: any) => state.chat);
  const addToChat = useChat((state: any) => state.addToChat);

  const onMessageReceived = (text: string) =>
    addToChat({ type: MessageType.Received, message: text });

  const [isReady, send] = useWebSocket(onMessageReceived);

  const onMessageSend = (text: string) => {
    const message = { type: MessageType.Sent, message: text };
    send(text);
    addToChat(message);
  };

  if (!isReady) return <FullScreenLoader />;

  return (
    <Layout>
      <HeaderLayout title="Talk to Us">
        <CustomButton
          variant="contained"
          startIcon={<AddIcCallIcon sx={{ fontSize: "inherit !important" }} />}
        >
          Call
        </CustomButton>
      </HeaderLayout>

      <Box
        sx={{
          p: 2,
          flex: 1,
          display: "flex",
          overflow: "auto",
          flexDirection: "column",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {chat.map((message: any, index: number) => {
          const { message: text, type } = message;
          const leftBorderRadius = "0px 16px 16px 16px";
          const rightBorderRadius = "15px 0px 15px 15px";
          const isSentMessage = type === MessageType.Sent;

          return (
            <Message
              text={text}
              sx={{
                mt: index !== 0 ? 2 : 0,
                borderRadius: isSentMessage
                  ? rightBorderRadius
                  : leftBorderRadius,
                alignSelf: isSentMessage ? "end" : "auto",
                backgroundColor: isSentMessage
                  ? "primary.light"
                  : "background.grey",
              }}
            />
          );
        })}
      </Box>

      <Footer send={onMessageSend} />
    </Layout>
  );
}

export default Chat;
