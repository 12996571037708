import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import useOrders from "./orders-hooks";
import { FullScreenLoader } from "../../components";

const MenuDrawer = () => {
  const { goBack, guest, order, loadingOrders } = useOrders();

  if (loadingOrders) {
    return <FullScreenLoader />;
  }

  return (
    <>
      {order?.length > 0 ? (
        <>
          <Typography
            component="div"
            variant="h6"
            sx={{ color: "gray", marginTop: 5, mb: 2 }}
          >
            MY ORDERS
          </Typography>
          <Divider />
          {order.map((v: any) => {
            return (
              <React.Fragment key={v.id}>
                {v.cart_items?.map((item: any) => {
                  return (
                    <React.Fragment key={item.id}>
                      <Card
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 3,
                        }}
                      >
                        <Box>
                          <CardContent sx={{ flex: "1 0 auto" }}>
                            <Typography component="div" variant="h5">
                              {item.name}
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography align="left" color="#fff">
                                {"quantity  " + item.quantity}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                            >
                              <Typography align="left" color="#fff">
                                {"status   " + v.status}
                              </Typography>
                            </Stack>

                            <Chip label={"$" + v.total} />
                          </CardContent>
                        </Box>
                        <Box padding={2}>
                          <img src={item.img} alt="img" width={100}></img>
                        </Box>
                      </Card>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <>
          <Box>
            <Typography
              component="div"
              variant="h6"
              sx={{ color: "gray", marginTop: 5, mb: 2 }}
            >
              NOT PLACED ORDERS
            </Typography>
            <Divider />
          </Box>

          <Box>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              marginTop="100px"
            >
              <Box
                style={{
                  width: "17%",
                  height: "17%",
                  backgroundColor: "#fff",
                }}
                component="img"
                alt="Orders"
                src="/../assets/orders.png"
              />
              <Typography
                sx={{
                  fontSize: 21,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                You have no orders yet
              </Typography>
            </Stack>
            <Button
              onClick={goBack}
              fullWidth
              sx={{
                backgroundColor: "#2196f3",
                paddingTop: 1,
                paddingBottom: 1,
                marginTop: 5,
              }}
              variant="contained"
            >
              <Typography sx={{ color: "white", fontSize: 20 }}>
                Main Menu
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default MenuDrawer;
