import * as React from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import Chat from "./Chat";
import Cart from "./Cart";
import Error from "./Error";
import Dining from "./Dining";
import Feedback from "./Feedback";
import HotelSer from "./HotelSer";
import { useAuth } from "../store";
import HotelInfo from "./HotelInfo";
import MenuDrawer from "./MyOrders";
import VerifyUser from "./VerifyUser";
import HotelRooms from "./HotelRooms";
import meQuery from "../utils/meQuery";
import { Dashboard } from "./Dashboard";
import { Promotions } from "./Promotions";
import InRoomDining from "./InRoomDining";
import HotelServices from "./HotelServices";
import { FullScreenLoader, Layout } from "../components";

const useGuestInfo = () => {
  const [getGuest] = useLazyQuery(meQuery);
  const [loading, setLoading] = React.useState(true);

  const setGuest = useAuth((state: any) => state.setGuest);

  React.useEffect(() => {
    async function getGuestInfo() {
      setLoading(true);
      const hostname = window.location.hostname;
      let propertyID = process.env.REACT_APP_PROPERTY_ID;
      if (hostname !== "localhost") {
        propertyID = hostname.split(".")[0];
      }
      const url = window.location.href;
      const roomID = url.substring(url.lastIndexOf("/") + 1);

      if (propertyID && roomID) {
        try {
          const { data } = await getGuest({
            query: meQuery,
            variables: {
              propertyID: propertyID,
              roomID: roomID,
            },
          });

          if (data.getGuest) {
            setGuest({ ...data.getGuest, room_id: roomID });
          }
        } catch (error) {}
      }
      setLoading(false);
    }

    getGuestInfo();
  }, [setGuest, getGuest, setLoading]);

  return { loading };
};

type RoutesProps = {
  children: JSX.Element;
};

function AuthRoutes({ children }: RoutesProps) {
  const guest = useAuth((state: any) => state.guest);
  const authCode = parseInt(guest.code);

  if (authCode !== 200) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <Navigate to="/login" state={{ from: location }} replace />;
    return <Navigate to="/error" />;
  }

  return children;
}

const AppRouter = () => {
  // TODO:
  // Fetching user info only once!
  // Need to check the edge case
  const { loading } = useGuestInfo();

  // TODO:
  // Add logic here to add auth i.e. navigate to login/auth page
  if (loading) return <FullScreenLoader />;

  return (
    <Router>
      <Routes>
        {/* Putting /error route first otherwise all the routes will match to /:roomID */}
        <Route path="/error" element={<Error />} />
        <Route
          path="/:roomID"
          element={
            <AuthRoutes>
              <Layout />
            </AuthRoutes>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="verifyUser" element={<VerifyUser />} />
          <Route path="cart" element={<Cart />} />
          <Route path="orders" element={<MenuDrawer />} />
          <Route path="chat" element={<Chat />} />
          <Route path="in-room-dining" element={<InRoomDining />} />
          <Route path="services" element={<HotelServices />} />

          <Route path="hotel-services/dining" element={<Dining />} />

          <Route path="hotel-services" element={<HotelSer />} />
          <Route path="promotions" element={<Promotions />} />

          <Route path="hotel-rooms" element={<HotelRooms />} />
          <Route path="hotel-information" element={<HotelInfo />} />
          <Route path="feedback" element={<Feedback />} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
