import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface FeedbackIconProps extends SvgIconProps {
  customColor?: string;
}

function SubmissionSuccessIcon(props: FeedbackIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 60 60" {...props}>
      <g clipPath="url(#clip0_206_517)">
        <path
          d="M45.8203 28.4578V0H11.577L0 11.577V56.4844H34.1309C36.9395 58.7618 40.4465 60.0032 44.0625 60C52.7859 60 60 52.9031 60 44.1797C60 36.0504 53.7199 29.3344 45.8203 28.4578ZM10.5469 6.00117V10.5469H6.00117L10.5469 6.00117ZM3.51562 14.0625H14.0625V3.51562H42.3047V28.4578C34.4051 29.3344 28.2422 36.0504 28.2422 44.1797C28.2387 47.3099 29.169 50.3701 30.9141 52.9688H3.51562V14.0625ZM44.0625 56.4844C40.7991 56.4844 37.6693 55.188 35.3618 52.8804C33.0542 50.5728 31.7578 47.4431 31.7578 44.1797C31.7578 40.9163 33.0542 37.7865 35.3618 35.479C37.6693 33.1714 40.7991 31.875 44.0625 31.875C50.8477 31.875 56.4844 37.3945 56.4844 44.1797C56.4844 50.9648 50.8477 56.4844 44.0625 56.4844Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M42.3046 46.9675L38.2746 42.9363L35.7878 45.423L42.3046 51.9386L52.3371 41.9074L49.8503 39.4207L42.3046 46.9675Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M17.5781 10.5469H38.7891V14.0625H17.5781V10.5469Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M7.03125 17.8125H38.7891V21.3281H7.03125V17.8125Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M7.03125 24.8438H35.2734V28.3594H7.03125V24.8438Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M7.03125 31.875H28.2422V35.3906H7.03125V31.875Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M7.03125 38.9062H23.2617V42.4219H7.03125V38.9062Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M7.03125 45.9375H23.2617V49.4531H7.03125V45.9375Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_206_517">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { SubmissionSuccessIcon };
