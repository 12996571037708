import create from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const GUEST_CART = "GUEST_CART";

let store: any = (set: any) => ({
  cart: [],
  addToCart: (id: string) =>
    set((state: any) => {
      const currentItem = state.cart.find((item: any) => item.id === id);
      const isPresent = Boolean(currentItem);

      if (!isPresent) {
        state.cart.push({ id, count: 1 });
      } else {
        currentItem.count += 1;
      }
    }),
  removeFromCart: (id: string) =>
    set((state: any) => {
      const itemIndex = state.cart.findIndex((item: any) => item.id === id);

      if (itemIndex === -1) return;

      const currentItem = state.cart[itemIndex];
      currentItem.count = Math.max(currentItem.count - 1, 0);
    }),
  resetCart: () =>
    set((state: any) => {
      state.cart = [];
    }),
});

store = immer(store);
store = persist(store, {
  name: GUEST_CART,
});

const useCart = create(store);

export { useCart };
