import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../store";
import { useGuestVerificationMutation } from "../../generated/graphql";

interface VerifyForm {
  name: string;
}

const useVerify = () => {
  const [verifyGuest, { loading: loadingGuestVerify }] =
    useGuestVerificationMutation();

  const guest = useAuth((state: any) => state.guest);
  const navigate = useNavigate();
  const {
    register: registerVerify,
    handleSubmit: handleSubmitVerify,
    reset: resetVerify,
    formState: { errors: errorsVerify, isDirty },
  } = useForm<VerifyForm>();

  const onSubmitVerify = async (data: VerifyForm) => {
    try {
      const res = await verifyGuest({
        variables: {
          guestName: data?.name,
          propertyID: guest?.property_id,
          roomID: guest?.room_id,
        },
      });

      if (res.data?.verifyGuest?.code == "200") {
        swal({
          text: "Verify Successfully",
          icon: "success",
        }).then((value) => {
          navigate(`/${guest.room_id}`);
        });
      } else {
        swal({
          text: res.data?.verifyGuest?.message,
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
    resetVerify();
  };

  return {
    registerVerify,
    handleSubmitVerify,
    resetVerify,
    errorsVerify,
    onSubmitVerify,
  };
};

export default useVerify;
