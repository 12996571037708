import swal from "sweetalert";
import * as React from "react";
import { isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Divider, Typography } from "@mui/material";

import { useAuth, useCart } from "../../store";
import { FullScreenLoader } from "../../components";
import { useMenuItem } from "../InRoomDining/room-dining-hook";
import { useCreateOrderMutation } from "../../generated/graphql";
import { FoodItemCard, HeaderLayout, Layout, Paper } from "../InRoomDining";

function MyCart() {
  const guest = useAuth((state: any) => state.guest);
  const { roomID } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useMenuItem();
  const cart = useCart((state: any) => state.cart);
  const [upsertOrder, { loading: loadingCreateOrder }] =
    useCreateOrderMutation();
  const resetCart = useCart((state: any) => state.resetCart);

  // Computed states
  const totalItems = React.useMemo(
    () => cart.reduce((prev: number, current: any) => prev + current.count, 0),
    [cart]
  );

  const foodItemMap = React.useMemo(() => {
    if (!data) return {};
    return data?.getEdibles?.reduce((acc: any, current: any) => {
      acc[current.id] = current;
      return acc;
    }, {});
  }, [data]);

  const cartItems = React.useMemo(() => {
    if (!data || !cart || !cart.length) return [];

    return cart
      .map((item: any) => item.id)
      .map((cartItemId: string) => {
        const item = { ...foodItemMap[cartItemId] };
        item.quantity = cart.find((f: any) => f.id === item.id)?.count || 0;

        return item;
      });
  }, [foodItemMap, cart]);

  const totalPrice = React.useMemo(() => {
    if (isEmpty(foodItemMap)) return 0;

    return cart.reduce((prev: number, current: any) => {
      const currentItem = foodItemMap[current.id];
      const itemCost = currentItem.price * current.count;

      return prev + itemCost;
    }, 0);
  }, [cart, foodItemMap]);

  const handleOrder = async () => {
    try {
      const orderData = cartItems.map((item: any) => ({
        dineInItem_id: item.id,
        quantity: item.quantity,
        extra_name: "xyz",
      }));

      const orderInput = {
        cart_items: orderData,
        property_id: guest?.property_id,
        guest_id: guest?.guest_id,
        guest_name: guest?.guest_name,
        room_id: parseInt(guest?.room_id),
        total: totalPrice,
      };

      const res = await upsertOrder({
        variables: {
          orderInput,
        },
      });

      if (res.data?.upsertOrder?.id) {
        swal({
          text: "Order Created Successfully",
          icon: "success",
        }).then(() => {
          resetCart();
          navigate(`/${roomID}`);
        });
      } else {
        swal({
          text: "Order Not Created",
          icon: "error",
        });
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  if (loading) return <FullScreenLoader />;

  return (
    <Layout>
      <HeaderLayout title="View Cart" />

      {/* TODO: Make a separate hidden scroll component*/}
      <Box
        sx={{
          p: 2,
          flex: 1,
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Typography variant="body2">{totalItems} Items</Typography>

        {cartItems.map((item: any) => {
          return <FoodItemCard key={item.id} item={item} />;
        })}

        <Paper sx={{ p: 2, mt: 3 }}>
          <Typography variant="h6">Billing Summary</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
              Total Items ({totalItems})
            </Typography>

            <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold" }}>
              AED {totalPrice}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ mt: 0.5, color: "text.secondary" }}
            >
              Service Cost
            </Typography>

            <Typography variant="body2" sx={{ mt: 0.5, fontWeight: "bold" }}>
              AED 0
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              sx={{ mt: 0.5, color: "text.secondary" }}
            >
              Tax
            </Typography>

            <Typography variant="body2" sx={{ mt: 0.5, fontWeight: "bold" }}>
              AED 0
            </Typography>
          </Box>

          <Divider sx={{ my: 1.5 }} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Total
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              AED {totalPrice}
            </Typography>
          </Box>
        </Paper>
      </Box>

      <Box sx={{ p: 1 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleOrder}
          disabled={totalItems === 0}
        >
          Make Order
        </Button>
      </Box>
    </Layout>
  );
}

export default MyCart;
