import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import useVerify from "./verify-hook";

const VerifyUser = () => {
  const {
    registerVerify,
    resetVerify,
    handleSubmitVerify,
    errorsVerify,
    onSubmitVerify,
  } = useVerify();

  return (
    <>
      <form onSubmit={handleSubmitVerify(onSubmitVerify)}>
        <Box
          sx={{
            marginTop: 20,
            "& > :not(style)": { m: 1, width: "100%" },
          }}
        >
          <Typography
            component="div"
            variant="h6"
            sx={{ color: "gray", marginTop: 20 }}
          >
            Please enter your name for the verification
          </Typography>
          <TextField
            {...registerVerify("name", {
              required: "Name is required!",
              maxLength: 30,
            })}
            id="outlined-basic"
            label="Enter your name"
            variant="outlined"
          />
          <Button variant="contained" type="submit">
            &nbsp; Verify&nbsp;
          </Button>
        </Box>
      </form>
    </>
  );
};

export default VerifyUser;
