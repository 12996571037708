import { useAuth } from "../../store";
import { useHotelDetailsQuery } from "../../generated/graphql";

const useHotelService = () => {
  const guest = useAuth((state: any) => state.guest);

  const { data: hotelService, loading: loadingService } = useHotelDetailsQuery({
    variables: {
      propertyID: guest?.property_id,
    },
  });
  return {
    hotelService: hotelService?.getMeta?.hotel_services,
    loadingService,
    guest,
  };
};

export default useHotelService;
