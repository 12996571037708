import { Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../store";
import { ChatIcon } from "../../../components";

const Header = () => {
  const guest = useAuth((state: any) => state.guest);
  const navigate = useNavigate();
  const guestName = guest?.guest_name || "";

  return (
    <Box
      sx={{
        px: 2,
        fontSize: 20,
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={() => navigate("chat")}
    >
      <Box>
        <Box>
          Hello
          <img
            src="/assets/hand-emoji.png"
            alt="Hello Emoji"
            style={{ verticalAlign: "bottom" }}
          />
        </Box>
        <Box>{guestName}</Box>
      </Box>

      <Chip
        variant="filled"
        color="primary"
        label="Talk to us"
        // icon={<TagFacesIcon />}
        deleteIcon={<ChatIcon />}
        onDelete={() => navigate("chat")}
      />
    </Box>
  );
};

export { Header };
