import { Box } from "@mui/material";

import useHotelService from "./hotel-ser-hooks";
import { HeaderLayout, Layout } from "../InRoomDining";
import { FullScreenLoader, ServiceCard } from "../../components";

const Services = () => {
  const { hotelService, loadingService, guest } = useHotelService();

  if (loadingService) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="Hotel Rooms" />
      <Box
        sx={{
          p: 2,
          pt: 0,
          flex: 1,
          overflow: "auto",
          background: "#F4F4F4",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {Array.isArray(hotelService) &&
          hotelService.map((hotelService: any) => {
            return (
              <ServiceCard
                showInfo={true}
                key={hotelService?.name}
                label={hotelService?.name}
                description={hotelService?.desc}
                image={hotelService?.img as string}
              />
            );
          })}

        <ServiceCard
          label="Dining"
          showInfo={false}
          path={`/${guest?.room_id}/hotel-services/dining`}
          image="https://cf.bstatic.com/xdata/images/hotel/max1024x768/65334861.jpg?k=b3c3e5a566144abd3c685f0c74608b93dea3ce3e03a3752975bd3e9605f5cc53&o=&hp=1"
        />
      </Box>
    </Layout>
  );
};

export default Services;
