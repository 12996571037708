import * as React from "react";
import { useTheme } from "@emotion/react";
import { camelCase, startCase } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useServiceList } from "../Dashboard";
import { useAuth, useServices } from "../../store";
import { usePropertyServicesMutationMutation } from "../../generated/graphql";

const useHotelServices = () => {
  const theme: any = useTheme();
  const guest = useAuth((state: any) => state.guest);
  const [requestService] = usePropertyServicesMutationMutation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const service = searchParams.get("service");
  const [currentService, setCurrentService] = React.useState(service);
  const currentServiceSimplified = startCase(
    camelCase(currentService as string)
  );

  const {
    servicesList,
    groupedServices,
    loading: loadingServiceList,
  } = useServiceList();
  const currentServiceList =
    groupedServices[currentServiceSimplified as string];

  // Modal
  const [open, setOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Service Request Flow
  const [requestingServices, setRequestingServices] = React.useState(false);
  const services = useServices((state: any) => state.services);
  const itemsInCart = React.useMemo(
    () =>
      services.reduce((prev: number, current: any) => prev + current.count, 0),
    [services]
  );

  const cancelServices = useServices((state: any) => state.cancelServices);
  const addToService = useServices((state: any) => state.addToService);
  const removeFromService = useServices(
    (state: any) => state.removeFromService
  );

  const submitServices = () => {
    setRequestingServices(true);
    const duplicateServiceSignal: any[] = [];
    const promisifiedService = services.map((service: any) => {
      return requestService({
        variables: {
          input: {
            property_id: guest?.property_id,
            quantity: service?.count,
            room_id: guest?.room_id,
            task_id: service?.id,
          },
        },
      }).then((res) => {
        if (res.data?.requestService?.code !== "200") {
          duplicateServiceSignal.push(false);
        }
      });
    });

    Promise.all(promisifiedService)
      .then(() => {
        const signal = duplicateServiceSignal.some((signal) => !signal);

        if (signal) {
          setModalMessage(
            "Request Submitted Successfully, and duplicate item skipped"
          );
        } else {
          setModalMessage("Submitted Successfully");
        }
        handleClickOpen();
      })
      .catch((err) => {
        setModalMessage(`${err}`);
        if (!open) handleClickOpen();
      })
      .finally(() => setRequestingServices(false));
  };

  return {
    loading: loadingServiceList || requestingServices,
    submitServices,
    servicesList,
    currentServiceList,
    itemsInCart,
    cancelServices,
    addToService,
    removeFromService,
    currentServiceSimplified,
    setCurrentService,
    navigate,
    open,
    handleClickOpen,
    handleClose,
    theme,
    modalMessage,
  };
};

export default useHotelServices;
