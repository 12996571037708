import {
  Box,
  Theme,
  SxProps,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { CardPaper } from "./CardPaper";

interface SliderProp {
  title: string;
  loading?: boolean;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  onTitleClick?: () => void;
}

const Slider = ({ title, children, sx, loading, onTitleClick }: SliderProp) => {
  return (
    <Box sx={{ mt: 3, ...(sx ? sx : {}) }}>
      <Typography
        variant="h6"
        sx={{ mb: 1 }}
        onClick={() => onTitleClick && onTitleClick()}
      >
        {title}
      </Typography>

      {!loading ? (
        <Box
          sx={{
            mt: 1,
            overflowX: "scroll",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {children}
        </Box>
      ) : (
        <Box sx={{ my: 4, display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

interface SliderCardProp {
  index: number;
  title: string;
  link: string;
  icon: React.ReactNode;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

const SliderCard = ({ index, title, link, icon, sx }: SliderCardProp) => {
  return (
    <RouterLink to={link}>
      <Box
        sx={{
          mt: 0.5,
          display: "inline-block",
          ml: index !== 0 ? 1.5 : 1,
          ...(sx ? sx : {}),
        }}
      >
        <CardPaper
          sx={{
            px: 3,
            py: 2.5,
            width: (theme) => theme.spacing(10),
          }}
        >
          {icon}
        </CardPaper>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ mt: 1.5, color: "text.primary" }}
        >
          {title}
        </Typography>
      </Box>
    </RouterLink>
  );
};

export { Slider, SliderCard };
