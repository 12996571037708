import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface FeedbackIconProps extends SvgIconProps {
  customColor?: string;
}

function FeedbackIcon(props: FeedbackIconProps) {
  const theme = useTheme();
  const { customColor, ...restProp } = props;
  return (
    <SvgIcon viewBox="0 0 60 60" {...restProp}>
      <g clipPath="url(#clip0_56_137)">
        <path
          d="M28.544 30.3976L38.4131 26.4384L48.281 30.3976L47.5694 19.7806L54.3787 11.6123L44.0744 9.00958L38.4131 0L32.753 9.00958L22.4487 11.6123L29.2593 19.7806L28.544 30.3976ZM41.7686 12.1797L47.8845 13.7229L43.8428 18.5719L44.2659 24.8697L38.4131 22.5203L32.5603 24.8685L32.9833 18.5707L28.9416 13.7217L35.0575 12.1797L38.4131 6.8384L41.7686 12.1797Z"
          fill={customColor || theme.palette.text.primary}
        />
        <path
          d="M51.4277 33.1723C50.4389 32.1844 49.0983 31.6295 47.7006 31.6295C46.3028 31.6295 44.9623 32.1844 43.9734 33.1723L36.5191 40.6266C36.1524 40.9934 35.7339 41.3045 35.277 41.55C35.4466 40.7799 35.4413 39.9815 35.2616 39.2137C35.0819 38.4458 34.7322 37.7281 34.2384 37.1133C33.7445 36.4985 33.1191 36.0022 32.4081 35.6611C31.6971 35.32 30.9187 35.1427 30.1301 35.1422H15.4688C14.3148 35.1391 13.1718 35.3649 12.1057 35.8064C11.0396 36.2479 10.0716 36.8964 9.25781 37.7145L0 46.9723L13.0277 60L20.3145 52.7121H35.7012C36.8555 52.7149 37.999 52.4884 39.0652 52.0459C40.1313 51.6033 41.099 50.9534 41.9121 50.134L51.4266 40.6207C52.4141 39.6319 52.9688 38.2916 52.9688 36.8941C52.9688 35.4967 52.4141 34.1564 51.4266 33.1676L51.4277 33.1723ZM48.9422 38.141L39.4289 47.6543C38.9408 48.1453 38.36 48.5346 37.7203 48.7995C37.0806 49.0644 36.3947 49.1998 35.7023 49.1977H18.859L13.0277 55.0301L4.96992 46.9723L11.7422 40.1953C12.2308 39.7052 12.8117 39.3168 13.4514 39.0525C14.091 38.7882 14.7767 38.6532 15.4688 38.6555H30.1289C30.5951 38.6555 31.0422 38.8407 31.3719 39.1703C31.7015 39.5 31.8867 39.9471 31.8867 40.4133C31.8867 40.8795 31.7015 41.3266 31.3719 41.6563C31.0422 41.9859 30.5951 42.1711 30.1289 42.1711H19.582V45.6867H32.7914C33.9457 45.6891 35.0891 45.4625 36.1552 45.0199C37.2213 44.5774 38.189 43.9277 39.0023 43.1086L46.4566 35.6543C46.7899 35.3438 47.2306 35.1748 47.686 35.1828C48.1414 35.1908 48.5759 35.3753 48.8979 35.6974C49.22 36.0194 49.4045 36.4539 49.4125 36.9093C49.4206 37.3647 49.2515 37.8055 48.941 38.1387L48.9422 38.141Z"
          fill={customColor || theme.palette.text.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_56_137">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { FeedbackIcon };
