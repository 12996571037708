import { Box } from "@mui/material";
import * as React from "react";
import { HeaderLayout, Layout } from "../InRoomDining";

type Props = {};

function Promotions({}: Props) {
  return (
    <Layout>
      <HeaderLayout title="Promotions" />

      <Box sx={{ p: 2 }}>
        {Array.from({ length: 4 }).map((item, index) => (
          <img key={index} src="/assets/promo.png" alt="Promo" />
        ))}
      </Box>
    </Layout>
  );
}

export { Promotions };
