import {
  Box,
  Button,
  Card,
  CardActions,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { FullScreenLoader } from "../../components";
import useFeedback from "./feedback-hook";
const Feedback = () => {
  const {
    registerFeedback,
    handleSubmitFeedback,
    onSubmitFeedback,
    feedbackType,
    loadingSubmitFeedback,
    loadingFeedbackList
  } = useFeedback();
  const [feedback, setFeedback] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setFeedback(event.target.value);
  };

  if (loadingFeedbackList) {
    return <FullScreenLoader />;
  }
  return (
    <>
      <form onSubmit={handleSubmitFeedback(onSubmitFeedback)}>
        <Card style={{ borderRadius: "15px", padding: 0 }}>
          <CardActions>
            <Box
              ml={1}
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  color: "#fff",
                  bottom: 10,
                }}
              >
                Select Feedback Type
              </Typography>
              <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={feedback}
                  {...registerFeedback("feedback_type_id", {
                    required: "Name is required!",
                  })}
                  label="Age"
                  onChange={handleChange}
                >
                  {feedbackType?.map((feedback: any) => {
                    return (
                      <MenuItem key={feedback.id} value={feedback.id}>
                        {feedback.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </CardActions>
        </Card>
        <br></br>
        <Card style={{ borderRadius: "15px", padding: 0 }}>
          <CardActions>
            <Box
              ml={1}
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  color: "#fff",
                  bottom: 10,
                }}
              >
                Leave your comment
              </Typography>

              <TextField
                {...registerFeedback("feedback", {
                  required: "Name is required!",
                  maxLength: 30,
                })}
                fullWidth
                id="standard-basic"
                label="Your comment"
                variant="standard"
              />
            </Box>
          </CardActions>
        </Card>
        <br></br>

        {!loadingSubmitFeedback ? (
          <Button
            sx={{
              backgroundColor: "#2196f3",
              marginRight: 5,
              marginBottom: 8,
            }}
            variant="contained"
            fullWidth
            type="submit"
          >
            <Typography align="center" component="div" sx={{ color: "#fff" }}>
              SEND
            </Typography>
          </Button>
        ) : (
          <FullScreenLoader />
        )}
      </form>
    </>
  );
};

export default Feedback;
