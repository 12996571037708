import { createContext } from "react";

export interface CartContextValue{
  cart:any[];
  addToCart?:(cart:any)=>void
  increaseCartItem?:(id:number)=>void
  decreaseCartItem?:(id:number)=>void
  deleteCartItem?:(id:number)=>void
  
}

export const CartContext = createContext<CartContextValue>({cart:[]})

