import { useAuth } from "../../store";
import { useHotelDetailsQuery } from "../../generated/graphql";

const useHotelInfo = () => {
  const guest = useAuth((state: any) => state.guest);

  const { data: hotelDetails, loading: loadingHotelDetails } =
    useHotelDetailsQuery({
      variables: {
        propertyID: guest?.property_id,
      },
    });
  return {
    hotelDetails: hotelDetails?.getMeta,
    loadingHotelDetails,
    guest,
  };
};

export default useHotelInfo;
