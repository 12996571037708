import { Box, SwipeableDrawer, Typography } from "@mui/material";

type DrawerProps = {
  name: string;
  desc: string;
  image: string;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
};

function Drawer(props: DrawerProps) {
  const { name, open, desc, image, handleOpen, handleClose } = props;

  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Box
        sx={{
          p: 2,
          overflow: "auto",
          maxHeight: "60vh",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          alt={name}
          src={image}
          component="img"
          sx={{ height: 250, width: "100%", objectFit: "cover" }}
        />

        <Typography
          variant="h6"
          sx={{ fontSize: (theme) => theme.spacing(2.5) }}
        >
          {name}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>{desc}</Typography>
      </Box>
    </SwipeableDrawer>
  );
}

export { Drawer };
