import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function PromotionsIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 60 60" {...props}>
      <g clipPath="url(#clip0_56_140)">
        <path
          d="M23.353 26.6566L25.8386 24.1711L28.3241 26.6566L25.8386 29.1422L23.353 26.6566Z"
          fill={theme.palette.text.primary}
        />
        <path
          d="M23.3098 41.6742L25.7954 39.1887L28.2809 41.6742L25.7954 44.1598L23.3098 41.6742Z"
          fill={theme.palette.text.primary}
        />
        <path
          d="M17.686 32.4H34.1157V35.9156H17.686V32.4Z"
          fill={theme.palette.text.primary}
        />
        <path
          d="M52.9383 17.6203C53.2064 16.4573 53.2343 15.2518 53.0203 14.0777C52.8063 12.9035 52.3549 11.7854 51.6937 10.7918L60 2.48555L57.5145 0L49.2082 8.30625C48.2146 7.6451 47.0965 7.19371 45.9223 6.97971C44.7482 6.76572 43.5427 6.79362 42.3797 7.06172C38.5163 7.96516 34.4901 7.9056 30.6551 6.88828L0 37.5434L22.4566 60L53.1105 29.3449C52.0976 25.5113 52.0385 21.4879 52.9383 17.6262V17.6203ZM41.8441 18.1559C42.0922 18.4038 42.2612 18.7197 42.3297 19.0637C42.3982 19.4076 42.3632 19.7642 42.229 20.0883C42.0949 20.4123 41.8676 20.6893 41.576 20.8842C41.2844 21.079 40.9415 21.1831 40.5908 21.1831C40.2401 21.1831 39.8972 21.079 39.6056 20.8842C39.314 20.6893 39.0868 20.4123 38.9526 20.0883C38.8185 19.7642 38.7834 19.4076 38.8519 19.0637C38.9204 18.7197 39.0894 18.4038 39.3375 18.1559C39.6701 17.8239 40.1209 17.6374 40.5908 17.6374C41.0608 17.6374 41.5115 17.8239 41.8441 18.1559V18.1559ZM22.4566 55.0277L4.97227 37.5434L31.7777 10.7379C35.4422 11.4562 39.2672 11.3742 43.159 10.4895C44.3302 10.22 45.5582 10.3576 46.6406 10.8797L42.8801 14.6402C41.7547 14.1014 40.475 13.9779 39.2673 14.2916C38.0596 14.6053 37.0018 15.3359 36.2809 16.3544C35.56 17.3728 35.2225 18.6133 35.3281 19.8566C35.4336 21.0999 35.9755 22.2658 36.8578 23.1481C37.7401 24.0304 38.906 24.5722 40.1492 24.6778C41.3925 24.7834 42.6331 24.4458 43.6515 23.725C44.6699 23.0041 45.4006 21.9462 45.7143 20.7386C46.028 19.5309 45.9045 18.2512 45.3656 17.1258L49.1262 13.3652C49.6469 14.446 49.7845 15.6717 49.5164 16.841C48.6316 20.7328 48.5496 24.5578 49.268 28.2223L22.4566 55.0277Z"
          fill={theme.palette.text.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_56_140">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { PromotionsIcon };
