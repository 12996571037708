import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: any;
};

export type About = {
  __typename?: "About";
  desc: Scalars["String"];
  img: Scalars["String"];
};

export type AboutInput = {
  desc: Scalars["String"];
  img: Scalars["String"];
};

export type Cart = {
  __typename?: "Cart";
  dineInItem_id: Scalars["String"];
  extra_name?: Maybe<Scalars["String"]>;
  quantity: Scalars["Int"];
};

export type CartInput = {
  dineInItem_id: Scalars["String"];
  extra_name?: InputMaybe<Scalars["String"]>;
  quantity: Scalars["Int"];
};

export type DineInItem = {
  __typename?: "DineInItem";
  availabletime?: Maybe<Scalars["String"]>;
  desc: Scalars["String"];
  extraOptions?: Maybe<Array<Maybe<ExtraOptions>>>;
  id: Scalars["ID"];
  img: Scalars["String"];
  isVegan: Scalars["Boolean"];
  name: Scalars["String"];
  price: Scalars["Float"];
  property_id: Scalars["String"];
  type?: Maybe<Types>;
};

export type DineInItemInput = {
  availabletime?: InputMaybe<Scalars["String"]>;
  desc: Scalars["String"];
  extraOptions?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  isVegan: Scalars["Boolean"];
  name: Scalars["String"];
  price: Scalars["Float"];
  property_id: Scalars["String"];
  type?: InputMaybe<Types>;
};

export type Dining = {
  __typename?: "Dining";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type DiningInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type DirectionsAndContact = {
  __typename?: "DirectionsAndContact";
  address: Scalars["String"];
  email: Scalars["String"];
  img: Scalars["String"];
  phone: Scalars["String"];
};

export type DirectionsAndContactInput = {
  address: Scalars["String"];
  email: Scalars["String"];
  img: Scalars["String"];
  phone: Scalars["String"];
};

export type ExtraOptions = {
  __typename?: "ExtraOptions";
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type ExtraOptionsInput = {
  name: Scalars["String"];
  price?: InputMaybe<Scalars["Float"]>;
};

export type Feedback = {
  __typename?: "Feedback";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type FeedbackInput = {
  feedback: Scalars["String"];
  feedback_type_id: Scalars["Int"];
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
};

export type HotelService = {
  __typename?: "HotelService";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type HotelServiceInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type MetaData = {
  __typename?: "MetaData";
  about: About;
  dining?: Maybe<Array<Maybe<Dining>>>;
  dir_con: DirectionsAndContact;
  hotel_rooms?: Maybe<Array<Room>>;
  hotel_services?: Maybe<Array<HotelService>>;
  id: Scalars["ID"];
  property_id: Scalars["String"];
};

export type MetaDataInput = {
  about: AboutInput;
  dir_con: DirectionsAndContactInput;
  id?: InputMaybe<Scalars["ID"]>;
  property_id: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  cancelOrder?: Maybe<Scalars["String"]>;
  changeOrderStatus?: Maybe<Scalars["String"]>;
  createFeedback?: Maybe<Response>;
  deleteDining?: Maybe<Scalars["String"]>;
  deleteEdible?: Maybe<Scalars["String"]>;
  deleteHotelService?: Maybe<Scalars["String"]>;
  deleteOrder?: Maybe<Scalars["String"]>;
  deleteProperty?: Maybe<Property>;
  deleteRoom?: Maybe<Scalars["String"]>;
  requestService?: Maybe<Response>;
  upsertDining?: Maybe<Scalars["String"]>;
  upsertEdible?: Maybe<DineInItem>;
  upsertHotelService?: Maybe<Scalars["String"]>;
  upsertMetaData?: Maybe<Scalars["String"]>;
  upsertOrder?: Maybe<Order>;
  upsertProperty?: Maybe<Property>;
  upsertRoom?: Maybe<Room>;
  verifyGuest?: Maybe<Response>;
};

export type MutationCancelOrderArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationChangeOrderStatusArgs = {
  id: Scalars["String"];
  orderStatus: Status;
  propertyID: Scalars["String"];
};

export type MutationCreateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};

export type MutationDeleteDiningArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteEdibleArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteHotelServiceArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteOrderArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeletePropertyArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteRoomArgs = {
  id: Scalars["ID"];
};

export type MutationRequestServiceArgs = {
  serviceInput: ServiceInput;
};

export type MutationUpsertDiningArgs = {
  diningInput: DiningInput;
};

export type MutationUpsertEdibleArgs = {
  dineInItemInput: DineInItemInput;
};

export type MutationUpsertHotelServiceArgs = {
  hotelServiceInput: HotelServiceInput;
};

export type MutationUpsertMetaDataArgs = {
  metaInput: MetaDataInput;
};

export type MutationUpsertOrderArgs = {
  orderInput: OrderInput;
};

export type MutationUpsertPropertyArgs = {
  propertyInput: PropertyInput;
};

export type MutationUpsertRoomArgs = {
  roomInput: RoomInput;
};

export type MutationVerifyGuestArgs = {
  guestName: Scalars["String"];
  propertyID: Scalars["String"];
  roomID: Scalars["Int"];
};

export type Order = {
  __typename?: "Order";
  cart_items?: Maybe<Array<Cart>>;
  guest_id: Scalars["Int"];
  guest_name: Scalars["String"];
  id: Scalars["ID"];
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
  status?: Maybe<Status>;
  total: Scalars["Float"];
};

export type OrderInput = {
  cart_items?: InputMaybe<Array<CartInput>>;
  guest_id: Scalars["Int"];
  guest_name: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
  total: Scalars["Float"];
};

export type Property = {
  __typename?: "Property";
  id: Scalars["ID"];
  img: Scalars["String"];
  name: Scalars["String"];
  primary_color: Scalars["String"];
  primary_light: Scalars["String"];
  primary_text_color: Scalars["String"];
  secondary_text_color: Scalars["String"];
};

export type PropertyInput = {
  api_key: Scalars["String"];
  domain: Scalars["String"];
  hotlync_api_url: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  name: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  getEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getFeedbackTypeList?: Maybe<Array<Maybe<Feedback>>>;
  getGuest?: Maybe<Response>;
  getGuestOrders?: Maybe<Array<Maybe<Order>>>;
  getMeta?: Maybe<MetaData>;
  getOrdersByStatus?: Maybe<Array<Maybe<Order>>>;
  getProperty?: Maybe<Property>;
  getServices?: Maybe<ServiceByDeptmt>;
};

export type QueryGetEdiblesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetFeedbackTypeListArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestArgs = {
  propertyID: Scalars["String"];
  roomID: Scalars["Int"];
};

export type QueryGetGuestOrdersArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
};

export type QueryGetMetaArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetOrdersByStatusArgs = {
  orderStatus: Status;
  propertyID: Scalars["String"];
};

export type QueryGetPropertyArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetServicesArgs = {
  propertyID: Scalars["String"];
};

export type Response = {
  __typename?: "Response";
  code: Scalars["String"];
  guest_id?: Maybe<Scalars["Int"]>;
  guest_name?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  property_id?: Maybe<Scalars["String"]>;
};

export type Room = {
  __typename?: "Room";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  property_id: Scalars["String"];
  room_type: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
};

export type RoomInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  property_id: Scalars["String"];
  room_type: Scalars["String"];
  size?: InputMaybe<Scalars["Int"]>;
};

export type Service = {
  __typename?: "Service";
  id: Scalars["Int"];
  task: Scalars["String"];
};

export type ServiceByDeptmt = {
  __typename?: "ServiceByDeptmt";
  code: Scalars["Int"];
  tasklist?: Maybe<Scalars["AWSJSON"]>;
};

export type ServiceInput = {
  property_id: Scalars["String"];
  quantity: Scalars["Int"];
  room_id: Scalars["Int"];
  task_id: Scalars["Int"];
};

export enum Status {
  Cancelled = "CANCELLED",
  Delivered = "DELIVERED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export enum Types {
  Alcohol = "ALCOHOL",
  Breakfast = "BREAKFAST",
  Desserts = "DESSERTS",
  Drinks = "DRINKS",
  HotDrinks = "HOT_DRINKS",
  Mains = "MAINS",
  Salads = "SALADS",
  Soups = "SOUPS",
  Starters = "STARTERS",
}

export type DineInListQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type DineInListQuery = {
  __typename?: "Query";
  getEdibles?: Array<{
    __typename?: "DineInItem";
    id: string;
    property_id: string;
    name: string;
    type?: Types | null;
    img: string;
    desc: string;
    price: number;
    isVegan: boolean;
    availabletime?: string | null;
    extraOptions?: Array<{
      __typename?: "ExtraOptions";
      name: string;
      price?: number | null;
    } | null> | null;
  } | null> | null;
};

export type FeedbackListQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type FeedbackListQuery = {
  __typename?: "Query";
  getFeedbackTypeList?: Array<{
    __typename?: "Feedback";
    id: number;
    name: string;
  } | null> | null;
};

export type SubmitFeedbackMutationVariables = Exact<{
  input: FeedbackInput;
}>;

export type SubmitFeedbackMutation = {
  __typename?: "Mutation";
  createFeedback?: {
    __typename?: "Response";
    code: string;
    message: string;
  } | null;
};

export type HotelDetailsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type HotelDetailsQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    about: { __typename?: "About"; desc: string; img: string };
    dining?: Array<{
      __typename?: "Dining";
      desc: string;
      id: string;
      img: string;
      name: string;
    } | null> | null;
    dir_con: {
      __typename?: "DirectionsAndContact";
      address: string;
      email: string;
      img: string;
      phone: string;
    };
    hotel_rooms?: Array<{
      __typename?: "Room";
      desc: string;
      id: string;
      img: string;
      room_type: string;
    }> | null;
    hotel_services?: Array<{
      __typename?: "HotelService";
      desc: string;
      id: string;
      img: string;
      name: string;
    }> | null;
  } | null;
};

export type CreateOrderMutationVariables = Exact<{
  orderInput: OrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: "Mutation";
  upsertOrder?: { __typename?: "Order"; id: string } | null;
};

export type MyOrdersQueryVariables = Exact<{
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
}>;

export type MyOrdersQuery = {
  __typename?: "Query";
  getGuestOrders?: Array<{
    __typename?: "Order";
    property_id: string;
    id: string;
    room_id: number;
    status?: Status | null;
    total: number;
    cart_items?: Array<{
      __typename?: "Cart";
      dineInItem_id: string;
      extra_name?: string | null;
      quantity: number;
    }> | null;
  } | null> | null;
};

export type PropertyServicesQueryVariables = Exact<{
  property_id: Scalars["String"];
}>;

export type PropertyServicesQuery = {
  __typename?: "Query";
  getServices?: {
    __typename?: "ServiceByDeptmt";
    code: number;
    tasklist?: any | null;
  } | null;
};

export type PropertyServicesMutationMutationVariables = Exact<{
  input: ServiceInput;
}>;

export type PropertyServicesMutationMutation = {
  __typename?: "Mutation";
  requestService?: {
    __typename?: "Response";
    code: string;
    message: string;
  } | null;
};

export type GuestVerificationMutationVariables = Exact<{
  guestName: Scalars["String"];
  propertyID: Scalars["String"];
  roomID: Scalars["Int"];
}>;

export type GuestVerificationMutation = {
  __typename?: "Mutation";
  verifyGuest?: {
    __typename?: "Response";
    code: string;
    message: string;
  } | null;
};

export const DineInListDocument = gql`
  query DineInList($propertyID: String!) {
    getEdibles(propertyID: $propertyID) {
      id
      property_id
      name
      type
      img
      desc
      price
      isVegan
      availabletime
      extraOptions {
        name
        price
      }
    }
  }
`;

/**
 * __useDineInListQuery__
 *
 * To run a query within a React component, call `useDineInListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDineInListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDineInListQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDineInListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DineInListQuery,
    DineInListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DineInListQuery, DineInListQueryVariables>(
    DineInListDocument,
    options
  );
}
export function useDineInListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DineInListQuery,
    DineInListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DineInListQuery,
    DineInListQueryVariables
  >(DineInListDocument, options);
}
export type DineInListQueryHookResult = ReturnType<typeof useDineInListQuery>;
export type DineInListLazyQueryHookResult = ReturnType<
  typeof useDineInListLazyQuery
>;
export type DineInListQueryResult = ApolloReactCommon.QueryResult<
  DineInListQuery,
  DineInListQueryVariables
>;
export const FeedbackListDocument = gql`
  query FeedbackList($propertyID: String!) {
    getFeedbackTypeList(propertyID: $propertyID) {
      id
      name
    }
  }
`;

/**
 * __useFeedbackListQuery__
 *
 * To run a query within a React component, call `useFeedbackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackListQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useFeedbackListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >(FeedbackListDocument, options);
}
export function useFeedbackListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FeedbackListQuery,
    FeedbackListQueryVariables
  >(FeedbackListDocument, options);
}
export type FeedbackListQueryHookResult = ReturnType<
  typeof useFeedbackListQuery
>;
export type FeedbackListLazyQueryHookResult = ReturnType<
  typeof useFeedbackListLazyQuery
>;
export type FeedbackListQueryResult = ApolloReactCommon.QueryResult<
  FeedbackListQuery,
  FeedbackListQueryVariables
>;
export const SubmitFeedbackDocument = gql`
  mutation submitFeedback($input: FeedbackInput!) {
    createFeedback(feedbackInput: $input) {
      code
      message
    }
  }
`;
export type SubmitFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  SubmitFeedbackMutation,
  SubmitFeedbackMutationVariables
>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >(SubmitFeedbackDocument, options);
}
export type SubmitFeedbackMutationHookResult = ReturnType<
  typeof useSubmitFeedbackMutation
>;
export type SubmitFeedbackMutationResult =
  ApolloReactCommon.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >;
export const HotelDetailsDocument = gql`
  query HotelDetails($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      about {
        desc
        img
      }
      dining {
        desc
        id
        img
        name
      }
      dir_con {
        address
        email
        img
        phone
      }
      hotel_rooms {
        desc
        id
        img
        room_type
      }
      hotel_services {
        desc
        id
        img
        name
      }
    }
  }
`;

/**
 * __useHotelDetailsQuery__
 *
 * To run a query within a React component, call `useHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelDetailsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useHotelDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >(HotelDetailsDocument, options);
}
export function useHotelDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    HotelDetailsQuery,
    HotelDetailsQueryVariables
  >(HotelDetailsDocument, options);
}
export type HotelDetailsQueryHookResult = ReturnType<
  typeof useHotelDetailsQuery
>;
export type HotelDetailsLazyQueryHookResult = ReturnType<
  typeof useHotelDetailsLazyQuery
>;
export type HotelDetailsQueryResult = ApolloReactCommon.QueryResult<
  HotelDetailsQuery,
  HotelDetailsQueryVariables
>;
export const CreateOrderDocument = gql`
  mutation CreateOrder($orderInput: OrderInput!) {
    upsertOrder(orderInput: $orderInput) {
      id
    }
  }
`;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >(CreateOrderDocument, options);
}
export type CreateOrderMutationHookResult = ReturnType<
  typeof useCreateOrderMutation
>;
export type CreateOrderMutationResult =
  ApolloReactCommon.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const MyOrdersDocument = gql`
  query MyOrders($guestID: Int!, $propertyID: String!) {
    getGuestOrders(guestID: $guestID, propertyID: $propertyID) {
      cart_items {
        dineInItem_id
        extra_name
        quantity
      }
      property_id
      id
      room_id
      status
      total
    }
  }
`;

/**
 * __useMyOrdersQuery__
 *
 * To run a query within a React component, call `useMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrdersQuery({
 *   variables: {
 *      guestID: // value for 'guestID'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useMyOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MyOrdersQuery,
    MyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyOrdersQuery, MyOrdersQueryVariables>(
    MyOrdersDocument,
    options
  );
}
export function useMyOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MyOrdersQuery,
    MyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyOrdersQuery, MyOrdersQueryVariables>(
    MyOrdersDocument,
    options
  );
}
export type MyOrdersQueryHookResult = ReturnType<typeof useMyOrdersQuery>;
export type MyOrdersLazyQueryHookResult = ReturnType<
  typeof useMyOrdersLazyQuery
>;
export type MyOrdersQueryResult = ApolloReactCommon.QueryResult<
  MyOrdersQuery,
  MyOrdersQueryVariables
>;
export const PropertyServicesDocument = gql`
  query propertyServices($property_id: String!) {
    getServices(propertyID: $property_id) {
      code
      tasklist
    }
  }
`;

/**
 * __usePropertyServicesQuery__
 *
 * To run a query within a React component, call `usePropertyServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyServicesQuery({
 *   variables: {
 *      property_id: // value for 'property_id'
 *   },
 * });
 */
export function usePropertyServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >(PropertyServicesDocument, options);
}
export function usePropertyServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PropertyServicesQuery,
    PropertyServicesQueryVariables
  >(PropertyServicesDocument, options);
}
export type PropertyServicesQueryHookResult = ReturnType<
  typeof usePropertyServicesQuery
>;
export type PropertyServicesLazyQueryHookResult = ReturnType<
  typeof usePropertyServicesLazyQuery
>;
export type PropertyServicesQueryResult = ApolloReactCommon.QueryResult<
  PropertyServicesQuery,
  PropertyServicesQueryVariables
>;
export const PropertyServicesMutationDocument = gql`
  mutation propertyServicesMutation($input: ServiceInput!) {
    requestService(serviceInput: $input) {
      code
      message
    }
  }
`;
export type PropertyServicesMutationMutationFn =
  ApolloReactCommon.MutationFunction<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >;

/**
 * __usePropertyServicesMutationMutation__
 *
 * To run a mutation, you first call `usePropertyServicesMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePropertyServicesMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [propertyServicesMutationMutation, { data, loading, error }] = usePropertyServicesMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePropertyServicesMutationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >(PropertyServicesMutationDocument, options);
}
export type PropertyServicesMutationMutationHookResult = ReturnType<
  typeof usePropertyServicesMutationMutation
>;
export type PropertyServicesMutationMutationResult =
  ApolloReactCommon.MutationResult<PropertyServicesMutationMutation>;
export type PropertyServicesMutationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PropertyServicesMutationMutation,
    PropertyServicesMutationMutationVariables
  >;
export const GuestVerificationDocument = gql`
  mutation guestVerification(
    $guestName: String!
    $propertyID: String!
    $roomID: Int!
  ) {
    verifyGuest(
      guestName: $guestName
      propertyID: $propertyID
      roomID: $roomID
    ) {
      code
      message
    }
  }
`;
export type GuestVerificationMutationFn = ApolloReactCommon.MutationFunction<
  GuestVerificationMutation,
  GuestVerificationMutationVariables
>;

/**
 * __useGuestVerificationMutation__
 *
 * To run a mutation, you first call `useGuestVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestVerificationMutation, { data, loading, error }] = useGuestVerificationMutation({
 *   variables: {
 *      guestName: // value for 'guestName'
 *      propertyID: // value for 'propertyID'
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useGuestVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >(GuestVerificationDocument, options);
}
export type GuestVerificationMutationHookResult = ReturnType<
  typeof useGuestVerificationMutation
>;
export type GuestVerificationMutationResult =
  ApolloReactCommon.MutationResult<GuestVerificationMutation>;
export type GuestVerificationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GuestVerificationMutation,
    GuestVerificationMutationVariables
  >;
