import * as React from "react";
import { useDineInListQuery } from "../../generated/graphql";
import { groupBy } from "lodash";
import { useAuth } from "../../store";

export const useMenuItem = () => {
  const guest = useAuth((state: any) => state.guest);

  const { data, loading } = useDineInListQuery({
    variables: {
      propertyID: guest?.property_id,
    },
  });

  return {
    data,
    loading,
  };
};

const useRoomDining = () => {
  const { data, loading } = useMenuItem();

  const menuItems = React.useMemo(() => {
    if (!data) return { categories: [], foodMenu: [] };

    const groupedMenu = groupBy(data?.getEdibles, (data: any) => data.type);
    const categories = Object.keys(groupedMenu);
    const iterable = categories.map((category) => {
      return {
        name: category,
        items: groupedMenu[category],
      };
    });

    return {
      categories,
      foodMenu: iterable,
    };
  }, [data]);

  return {
    loading,
    ...menuItems,
  };
};

export default useRoomDining;
