import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useAuth } from "../../store";
import { useDineInListQuery, useMyOrdersQuery } from "../../generated/graphql";

const useOrders = () => {
  const navigate = useNavigate();
  const guest = useAuth((state: any) => state.guest);

  const goBack = () => {
    navigate(`/${guest?.room_id}/in-room-dining`);
  };

  const [order, setOrder] = useState<any>();

  const { data: orders, loading: loadingOrders } = useMyOrdersQuery({
    variables: {
      guestID: guest?.guest_id,
      propertyID: guest?.property_id,
    },
  });
  const { data: dineInList, loading: loadingdineInList } = useDineInListQuery({
    variables: {
      propertyID: guest?.property_id,
    },
  });

  useEffect(() => {
    if (orders?.getGuestOrders && dineInList?.getEdibles) {
      const test = orders?.getGuestOrders;
      const MenuList = test?.map((order) => {
        const item = order?.cart_items?.map((v) => {
          const list = dineInList?.getEdibles?.find(
            (item) => item?.id === v?.dineInItem_id
          );
          return { ...v, ...list };
        });
        return { ...order, cart_items: item };
      });

      setOrder(MenuList);
    }
  }, [orders, dineInList]);

  return {
    goBack,
    guest,
    order,
    loadingOrders,
  };
};

export default useOrders;
