import { merge, cloneDeep } from "lodash";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material/styles";

import zIndex from "./z-index";
import palette from "./palette";
import typography from "./typography";
import componentsOverride from "./overrides";
import { delay, useDeepCompareMemoize } from "../utils";
import { FullScreenLoader } from "../components";

interface Props {
  children: React.ReactNode;
}

// TODO:
// This hook will be replaced with an actual GraphQl call
// Color codes will be dynamic
function useColors(): { loading: boolean; data: any } {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  // Main design - #DC0A6F
  // Light design - #FFEBF5
  useEffect(() => {
    delay(500).finally(() => {
      setLoading(false);
      setData({
        text: {
          primary: "#161449",
          secondary: "#504F6F",
        },
        color: {
          primary: "#005395",
          primaryLight: "#b1c5e4",
        },
        // Currency
        logoUrl: "/assets/app-logo.png",
      });
    });
  }, []);

  return {
    loading,
    data,
  };
}

const ThemeConfig = ({ children }: Props) => {
  // Call an API? to fetch the font colors from the backend
  const { loading, data } = useColors();
  const memoisedConfig = useDeepCompareMemoize(data);

  const themeOptions = useMemo(() => {
    const { text, color } = data;
    let paletteCopy = cloneDeep(palette);

    if (text && color) {
      const { primary: main, primaryLight: light } = color;
      const clientThemeColors = { text, primary: { main, light } };
      paletteCopy = merge(clientThemeColors, palette);
    }

    // TODO: Solve chicken egg problem here :D
    // context: which palette to show first || no pallet ( default )
    return {
      zIndex,
      typography,
      palette: paletteCopy,
    };
  }, [memoisedConfig]);

  // Temp FIX for above TODO
  if (loading) return <FullScreenLoader />;

  const theme = createTheme(themeOptions as ThemeOptions);
  theme.components = componentsOverride(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export { ThemeConfig };
